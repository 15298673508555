import React, { Component } from "react"
import { navigate } from "gatsby-link"

// Styles
import styles from "../../styles/components/quote/quoteForm.module.scss"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

// The date values on state are for the form only. To change functionality, edit datePicker.js
class QuoteForm extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    address: "",
    bill: "",
    message: "",
    emailValid: false,
    isSubmit: false,
  }

  handleChange = e => {
    if (e.target.name === "email") {
      this.validateEmail(e.target.value)
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ isSubmit: true })
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "quote",
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => {
        alert(error)
        this.setState({ isSubmit: false })
      })
  }

  validateEmail = email => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.setState({
      emailValid: re.test(String(email).toLowerCase()),
    })
  }

  render() {
    return (
      <div className="contactForm">
        <form
          className={styles.form}
          name="quote"
          method="post"
          action="/success/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="quote" />
          <label style={{ display: "none" }}>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>

          <div className={styles.grid}>
            <div className={styles.group1}>
              <p className={styles.heading}>Contact Information</p>
              <label htmlFor="name" className={styles.name}>
                <input
                  name="name"
                  type="text"
                  value={this.state.name}
                  placeholder="Your Name"
                  onChange={this.handleChange}
                />
              </label>
              <label htmlFor="email" className={styles.email}>
                <input
                  name="email"
                  type="email"
                  value={this.state.email}
                  placeholder="Email Address"
                  onChange={this.handleChange}
                />
              </label>

              <label htmlFor="phone" className={styles.phone}>
                <input
                  name="phone"
                  type="tel"
                  value={this.state.phone}
                  placeholder="Phone Number"
                  onChange={this.handleChange}
                />
              </label>
            </div>
            <div className={styles.group2}>
              <p className={styles.heading}>Project Information</p>
              <label htmlFor="address" className={styles.address}>
                <input
                  name="address"
                  type="text"
                  value={this.state.address}
                  placeholder="Address"
                  onChange={this.handleChange}
                />
              </label>
              <label htmlFor="bill" className={styles.bill}>
                <input
                  name="bill"
                  type="number"
                  value={this.state.bill}
                  placeholder="Monthly Electricity Bill"
                  onChange={this.handleChange}
                />
              </label>
              <label htmlFor="message" className={styles.message}>
                <input
                  name="message"
                  type="text"
                  value={this.state.message}
                  placeholder="Additional Information"
                  onChange={this.handleChange}
                />
              </label>
            </div>
          </div>
          <button
            className={styles.btn}
            onClick={this.handleSubmit}
            disabled={this.state.isSubmit}
          >
            Submit
          </button>
        </form>
      </div>
    )
  }
}

export default QuoteForm
